import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useEffect } from "react"
import { useState } from "react"
import BlogCard from "../components/blog/blogCard"
import AuthorCard from "../components/blog/authorCard"
import PageNumber from "../components/blog/pageNumber"
import { Breadcrumbs } from "../components/blog/breadcrumbs"
import Seo from "../components/seo"
import HeroBlog from "../components/blog/hero"
import Navbar from "../components/blog/navbar"

const BlogList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .resize {
    width: 100%;
    max-width: 1280px;
  }
`

const ArticleContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 5% 5%;
  padding: 0 5%;
`

export default function Blog({ data, location, pageContext }) {
  const posts = data.allPrismicPdfproPost.nodes
  console.log("test", posts[0])
  console.log(posts)
  const [checklocation, setCheckLocation] = useState("/")
  const [page, setPage] = useState(1)

  let blogNumber = 0

  useEffect(() => {
    let params = new URLSearchParams(document.location.search)
    let page = params.get("page") // is the string "Jonathan"
    console.log("Page: " + page)
    if (page) setPage(page)
  }, [])

  useEffect(() => {
    setCheckLocation(window.location.pathname)
  }, [])

  return (
    <BlogList>
      <Seo title={posts[0].data.author.document.data.name} />
      <HeroBlog />
      <Navbar categories={pageContext.categoryTypes} />
      <Breadcrumbs
        currentPath={["/"].concat(
          location.pathname.split("/").filter(name => name !== "")
        )}
      />
      <div className="resize">
        <AuthorCard
          posts={posts[0].data.author}
          author={posts[0].data.author.document.data.name}
          bio={true}
        />

        <ArticleContainer>
          {posts.map((post, i) => {
            blogNumber = i
            return (
              <BlogCard
                post={post}
                author={post.data.author.document.data.name}
                visible={
                  i <= 8 * (page - 1) - 1 || i > 8 * page - 1 ? "none" : "flex"
                }
              />
            )
          })}
          {/* </div> */}
        </ArticleContainer>
        <PageNumber
          location={checklocation}
          blogNumber={blogNumber}
          setPage={setPage}
          page={page}
        />
      </div>
    </BlogList>
  )
}

export const query = graphql`
  query BlogFilterQuery($id: ID!) {
    allPrismicPdfproPost(
      sort: { fields: data___publish_date, order: DESC }
      filter: { data: { author: { id: { eq: $id } } } }
    ) {
      nodes {
        data {
          url
          title
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          fit_image
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                  image {
                    gatsbyImageData
                  }
                  shortbio {
                    text
                  }
                  title
                  twitter
                  linkedin
                  facebook
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`
